@import 'variables.scss';
* {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: inherit;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
}

html {
    font-size: 14px;
    box-sizing: border-box;
}

body {
    font-size: 1rem;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    padding-top: var(--header-height);
    width: 100vw;
    height: 100vh;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    background-color: transparent;
    cursor: pointer;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
label {
    font-weight: 600;
}

#root,
.app {
    height: 100%;
    width: 100%;
    display: flex;
}

#holonext-alert {
    bottom: 17px;
    left: 20%;
    height: 50px;
    border-radius: 10px;
    width: 360px;
    position: absolute;
}
