@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,700;1,600&display=swap');

:root {
    --black-primary: #0e0620;
    --white: #ffffff;
    --color-primary: #1380b8;
    --color-secondary: #f5f5f5;
    --green: #2ccf6d;
    --i1d: 38, 38, 38;
    --edc: 199, 199, 199;
    --f75: 38, 38, 38;
    --fe0: 0, 55, 107;
    --d87: 255, 255, 255;
    --b3f: 250, 250, 250;
    --bb2: 239, 239, 239;
    --f23: 255, 255, 255;
    --b38: 219, 219, 219;
    --b6a: 219, 219, 219;
    --ca6: 219, 219, 219;
    --cdd: 38, 38, 38;
    --e22: 199, 199, 199;
    --e62: 245, 251, 255;
    --b2f: 88, 195, 34;
    --c8c: 168, 168, 168;
    --ce3: 239, 239, 239;
    --jd9: 0, 0, 0;
    --j64: 54, 54, 54;
    --a97: 243, 243, 243;
    --d20: 38, 38, 38;

    --f52: 142, 142, 142;
    --h1d: 255, 255, 255;
    --de5: 255, 255, 255;
    --d69: 0, 149, 246;
    --b86: 88, 195, 34;
    --i30: 237, 73, 86;
    --d62: 255, 255, 255;
    --a72: 255, 255, 255;
    --ie3: 142, 142, 142;
    --c37: 237, 73, 86;
    --f24: 0, 149, 246;
    --jbb: 53, 121, 234;
    --eca: 255, 255, 255;
    --jb7: 0, 0, 0;
    --fa7: 224, 241, 255;
    --ba8: 168, 168, 168;
    --eac: 237, 73, 86;
    --bg: 238, 238, 238;

    --button-bg-black: #151515;
    --button-bg-disabled: #2c3e50;
    --button-bg-white: #fff;
    --button-bg-secondary: rgba(21, 21, 21, 0.8);
    --button-bg-lg: #0f0138;

    --bg-white: #fff;
    --bg-modal-transparent: rgba(238, 238, 238, 0.85);

    --border-list-item-hover: #0e0620;
    --border-color-primary: #89cff0;
    --border-color-secondary: #6495ed;
    --border-color-hover: #29a7e1;
    /*gap sizes*/
    --gap-xxxs: 0.625rem;
    --gap-xxs: 0.75rem;
    --gap-xs: 0.875rem;
    --gap-sm: 1rem; // 16px
    --gap-ms: 1.25rem; //20px
    --gap-md: 1.5rem; // 24px
    --gap-lg: 2rem;
    --gap-xl: 3rem;
    --gap-xxl: 4rem;
    --gap-xxxl: 5rem;

    /*font sizes*/
    --font-size-xxxs: 0.625rem;
    --font-size-xxs: 0.75rem; //12px
    --font-size-xs: 0.875rem; // 14px
    --font-size-sm: 1rem; // 16px
    --font-size-sx: 1.25rem; //20px
    --font-size-md: 1.5rem;
    --font-size-lg: 2rem;
    --font-size-xl: 3rem;
    --font-size-xxl: 4rem;
    --font-size-xxxl: 5rem;

    /*border radius*/

    --border-radius-xxxs: 0.25rem;
    --border-radius-xxs: 0.5rem;
    --border-radius-xs: 0.75rem;
    --border-radius-sm: 1rem;
    --border-radius-md: 1.5rem;
    --border-radius-lg: 2rem;
    --border-radius-xl: 3rem;
    --border-radius-xxl: 4rem;
    --border-radius-xxxl: 5rem;

    --border-radius-half: 50%;
    --border-radius-primary: 45px;

    /**
    * font family*/

    --font-family-primary: 'Nunito', sans-serif;
}
.Toastify__toast-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 400px;
    gap: 1rem;
    border-radius: 10px;
}
