.spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    --angle: 30deg;
    --delay: 0.5s;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.01);
    z-index: 9999;

    .wrapper {
        position: absolute;
        left: calc(50% - 165px);
        top: 50%;
        width: 330px;
        height: 5px;
        background: transparent;
        animation: 6s lds-default linear infinite;

        div {
            height: 20px;
            width: 20px;
            position: relative;
            /*some prefix*/

            border-radius: 50%;
            background-color: #9b93ae;
            border: 1px solid rgba(255, 255, 255, 0.3);

            animation: infinite 1s spinner linear;
        }
    }

    .spinner-image {
        position: absolute;
        top: calc(50% - 100px);
        width: 220px;
        height: 220px;
        left: calc(50% - 110px);
        background: rgb(224, 226, 224);
        background: radial-gradient(
            circle,
            rgba(224, 226, 224, 1) 3%,
            rgba(188, 181, 197, 0.5872942927170868) 93%
        );
        border-radius: 50%;
        animation: infinite 10s logo linear;
    }

    @for $i from 1 through 12 {
        :nth-child(#{$i}) {
            transform: rotate(calc(#{$i} * 30deg));
            --rotation: calc(#{$i} * 30deg);
        }
    }

    @keyframes lds-default {
        to {
            transform: rotate(calc(360deg + var(--rotation)));
        }
    }
    @keyframes logo {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.95);
        }
        90% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes spinner {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.8);
        }
        90% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
}

.spinner-container.hidden {
    display: none;
}
